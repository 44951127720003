<template>
<div>
  <button class="btn btn-primary" @click="registerUser">Register User</button>
</div>
</template>

<script>
import axios from "axios";
import { getLog } from "@/services/log";
let log = getLog('test');

export default {
  methods: {
    registerUser() {
      axios.post("https://api.hsforms.com/submissions/v3/integration/submit/6855247/07459b9e-bbe9-43a2-b559-323638334c17",
        {
          fields: [
            { name: "email", value: "formtest@treasureapp.io" },
            { name: "age", value: 32 },
            { name: "gender", value: "m" },
            { name: "interest", value: "f" }
          ],
        })
        .then(response => {
          log.log("response", response)
        })
        .catch(error => {
          log.error("post error: ", error);
        })
    }
  }
}
</script>

<style>

</style>